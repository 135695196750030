<template>
  <!-- <v-container id="dashboard" fluid tag="section"> -->
  <v-row>
    <v-col cols="12">
      <base-material-card color="success" inline title="Tools" class="px-5 py-3">
        <v-col cols="12">
          <v-subheader>{{ $t("Import CVE") }}</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("File") }}
            </v-col>
            <v-col cols="9">
              <add-file
                :value="record.cve_file_name"
                collection="nvd_media_upload"
                :hide-details="hided"
                :dense="dense"
                @input="
                  record.cve_file = $event.nvd_media_id
                  $set(record, 'cve_file_name', $event.file)
                  cvefileLoaded = true
                "
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="primary" :disabled="importing || !cvefileLoaded" @click="startImportCve">
            {{ $t("Import") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-subheader>{{ $t("Import CPE") }}</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("File") }}
            </v-col>
            <v-col cols="9">
              <add-file
                :value="record.cpe_file_name"
                collection="nvd_media_upload"
                :hide-details="hided"
                :dense="dense"
                @input="
                  record.cpe_file = $event.nvd_media_id
                  $set(record, 'cpe_file_name', $event.file)
                  cpefileLoaded = true
                "
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="primary" :disabled="importing || !cpefileLoaded" @click="startImportCpe">
            {{ $t("Import") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-subheader>{{ $t("Import CWE") }}</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("File") }}
            </v-col>
            <v-col cols="9">
              <add-file
                :value="record.cwe_file_name"
                collection="nvd_media_upload"
                :hide-details="hided"
                :dense="dense"
                @input="
                  record.cwe_file = $event.nvd_media_id
                  $set(record, 'cwe_file_name', $event.file)
                  cwefileLoaded = true
                "
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="primary" :disabled="importing || !cwefileLoaded" @click="startImportCwe">
            {{ $t("Import") }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-subheader>{{ $t("Import Software bugs") }}</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("File") }}
            </v-col>
            <v-col cols="9">
              <add-file
                :value="record.app_file_name"
                collection="nvd_media_upload"
                :hide-details="hided"
                :dense="dense"
                @input="
                  record.app_file = $event.nvd_media_id
                  $set(record, 'app_file_name', $event.file)
                  appfileLoaded = true
                "
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="primary" :disabled="importing || !appfileLoaded" @click="startImportApp">
            {{ $t("Import") }}
          </v-btn>
        </v-col>
      </base-material-card>
    </v-col>
  </v-row>
  <!-- </v-container> -->
</template>
<script>
import AddFile from "../../../components/containers/AddFile.vue"
import axios from "../../../plugins/axios"
export default {
  name: "Home",
  components: {
    AddFile
  },
  data() {
    return {
      year: 2020,
      type: "if",
      importing: false,
      cvefileLoaded: false,
      cpefileLoaded: false,
      cwefileLoaded: false,
      appfileLoaded: false,
      dense: true,
      hided: false,
      records: [],
      index: -1,
      subindex: 0,
      record: { cve_file_name: "" }
    }
  },
  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {},
  methods: {
    fileSelected(e) {
      this.$log("fileSelected ", e)
      this.record.file = e.target.files[0]
      this.$set(this.record, "file_name", e.target.files[0].name)
    },
    startImport() {},
    startImportCwe() {
      this.$log("startImportCwe ", this.record.cwe_file)
      axios.get("import_cwe_xml/" + this.record.cwe_file).then(res => {
        this.$log("import ", res)
      })
    },
    startImportCve() {
      axios.get("import_cve_xml/" + this.record.cve_file).then(res => {
        this.$log("import ", res)
      })
    },
    startImportCpe() {
      axios.get("import_cpe_xml/" + this.record.cpe_file).then(res => {
        this.$log("import ", res)
      })
    },
    startImportApp() {
      axios.get("import_application_bugs/" + this.record.app_file).then(res => {
        this.$log("import ", res)
      })
    },
    importData() {
      const rec = {}
      //this.$log("rec ", rec)
      axios.post("infuni_academics", rec).finally(() => {
        this.importData()
      })
    }
  }
}
</script>
<style></style>
