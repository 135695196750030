var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"success","inline":"","title":"Tools"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Import CVE")))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-no-wrap",attrs:{"dense":_vm.dense,"align":"center"}},[_c('v-col',{staticClass:"text-right grey--text",class:{ 'pt-3': _vm.hided },attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("File"))+" ")]),_c('v-col',{attrs:{"cols":"9"}},[_c('add-file',{attrs:{"value":_vm.record.cve_file_name,"collection":"nvd_media_upload","hide-details":_vm.hided,"dense":_vm.dense},on:{"input":function($event){_vm.record.cve_file = $event.nvd_media_id
                _vm.$set(_vm.record, 'cve_file_name', $event.file)
                _vm.cvefileLoaded = true}}})],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.importing || !_vm.cvefileLoaded},on:{"click":_vm.startImportCve}},[_vm._v(" "+_vm._s(_vm.$t("Import"))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Import CPE")))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-no-wrap",attrs:{"dense":_vm.dense,"align":"center"}},[_c('v-col',{staticClass:"text-right grey--text",class:{ 'pt-3': _vm.hided },attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("File"))+" ")]),_c('v-col',{attrs:{"cols":"9"}},[_c('add-file',{attrs:{"value":_vm.record.cpe_file_name,"collection":"nvd_media_upload","hide-details":_vm.hided,"dense":_vm.dense},on:{"input":function($event){_vm.record.cpe_file = $event.nvd_media_id
                _vm.$set(_vm.record, 'cpe_file_name', $event.file)
                _vm.cpefileLoaded = true}}})],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.importing || !_vm.cpefileLoaded},on:{"click":_vm.startImportCpe}},[_vm._v(" "+_vm._s(_vm.$t("Import"))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Import CWE")))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-no-wrap",attrs:{"dense":_vm.dense,"align":"center"}},[_c('v-col',{staticClass:"text-right grey--text",class:{ 'pt-3': _vm.hided },attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("File"))+" ")]),_c('v-col',{attrs:{"cols":"9"}},[_c('add-file',{attrs:{"value":_vm.record.cwe_file_name,"collection":"nvd_media_upload","hide-details":_vm.hided,"dense":_vm.dense},on:{"input":function($event){_vm.record.cwe_file = $event.nvd_media_id
                _vm.$set(_vm.record, 'cwe_file_name', $event.file)
                _vm.cwefileLoaded = true}}})],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.importing || !_vm.cwefileLoaded},on:{"click":_vm.startImportCwe}},[_vm._v(" "+_vm._s(_vm.$t("Import"))+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Import Software bugs")))])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"text-no-wrap",attrs:{"dense":_vm.dense,"align":"center"}},[_c('v-col',{staticClass:"text-right grey--text",class:{ 'pt-3': _vm.hided },attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t("File"))+" ")]),_c('v-col',{attrs:{"cols":"9"}},[_c('add-file',{attrs:{"value":_vm.record.app_file_name,"collection":"nvd_media_upload","hide-details":_vm.hided,"dense":_vm.dense},on:{"input":function($event){_vm.record.app_file = $event.nvd_media_id
                _vm.$set(_vm.record, 'app_file_name', $event.file)
                _vm.appfileLoaded = true}}})],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.importing || !_vm.appfileLoaded},on:{"click":_vm.startImportApp}},[_vm._v(" "+_vm._s(_vm.$t("Import"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }